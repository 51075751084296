import { TweenMax } from 'gsap';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

//polyfill for each
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

//ie11 detection
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if(isIE11) {
  document.body.classList.add('is-ie11');
}

//windows OS detection to compensate line-heights
if (navigator.appVersion.indexOf("Win")!=-1) {
  document.body.classList.add('is-windows');
};


//stats:
//var script=document.createElement('script');script.onload=function(){var stats=new Stats();document.body.appendChild(stats.dom);requestAnimationFrame(function loop(){stats.update();requestAnimationFrame(loop)});};script.src='//mrdoob.github.io/stats.js/build/stats.min.js';document.head.appendChild(script);


//general
let windowW = 0;
let windowH = 0;


//detect hover
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

if(is_touch_device() == true){
  document.body.classList.add("touch");
}



//svg quotes
const SVGQuotes = function () {

  this.settings = {
    quotesDimensionsRatio: 560/1280,
    firstAnimationDelay: 1000,
    groupsAnimationInDuration: 0.5,
    groupsAnimationInDelayStep: 0.1,
    groupsAnimationInEase: Expo.easeOut,
    groupsAnimationOutDuration: 0.2,
    groupsAnimationOutDelayTotal: 0.5,
    groupsAnimationOutEase: Expo.easeIn,
    quotesAnimationInBetweenDelay: 500,
    changeIntervalDuration:8000,
    relaunchChangeIntervalTimeoutDuration: 10000
  }

  this.refs = {
    $container: undefined,
    $svgsContainer: undefined,
    $svgs: undefined,
    $currentQuote: undefined,
    $previousQuote: undefined,
    changeInterval: undefined,
    relaunchChangeIntervalTimeout: undefined,
    revealAuthorTimeout: undefined,
    animationOutTimeout: undefined
  }

  this.currentID = 0;
  this.isAnimating = false;

  this.init = (firstQuoteID) => {
    this.currentID = firstQuoteID;

    this.refs.$container = document.querySelector('.svg-quotes');
    this.refs.$svgsContainer = this.refs.$container.querySelectorAll('.svg-container');
    this.refs.$svgs = this.refs.$container.querySelectorAll('svg');

    this.refs.$currentQuote = this.refs.$svgsContainer[this.currentID];
    this.refs.$currentQuote.classList.add('active');
    this.refs.$currentQuote.classList.add('hidden');

    this.resize();

    setTimeout(()=> {
      this.refs.$currentQuote.classList.remove('hidden');
      this.animateQuoteIn(this.refs.$currentQuote);
    }, this.settings.firstAnimationDelay);

    this.refs.$container.addEventListener('click', this.quoteClickListener);
    this.refs.$container.addEventListener('touchstart', this.quoteClickListener);

    this.startInterval();
    window.addEventListener("blur", this.stopInterval, false);
    window.addEventListener("focus", this.startInterval, false);
  }

  this.startInterval = () => {
    if(this.refs.changeInterval == undefined){
      this.refs.changeInterval = setInterval(this.nextQuote, this.settings.changeIntervalDuration);
    }
  }

  this.stopInterval = () => {
    if(this.refs.changeInterval != undefined){
      clearInterval(this.refs.changeInterval);
      this.refs.changeInterval = undefined;
    }
    if(this.refs.relaunchChangeIntervalTimeout != undefined){
      clearTimeout(this.refs.relaunchChangeIntervalTimeout);
      this.refs.relaunchChangeIntervalTimeout = undefined;
    }
  }

  this.killInterval = () => {
    this.stopInterval();

    if(this.refs.relaunchChangeIntervalTimeout != undefined){
      clearTimeout(this.refs.relaunchChangeIntervalTimeout);
    }
    this.refs.relaunchChangeIntervalTimeout = setTimeout(this.startInterval, this.settings.relaunchChangeIntervalTimeoutDuration);
  }

  this.resize = () => {
    const svgWidth = this.refs.$svgs[this.currentID].clientWidth || this.refs.$svgs[this.currentID].offsetWidth;
    this.refs.$svgs[this.currentID].style.height = svgWidth * this.settings.quotesDimensionsRatio + 'px';
  }


  this.quoteClickListener = () => {
    this.killInterval();
    this.nextQuote();
  }

  this.animateQuoteIn = (quote) => {
    this.isAnimating = true;

    requestAnimationFrame(()=> {
      quote.classList.add('scaling');
    });

    const quoteGroups = quote.querySelectorAll('g');

    quoteGroups.forEach((group, i) => {
      TweenMax.to(group, 0, { scale: 0 });
      TweenMax.to(group, this.settings.groupsAnimationInDuration, {
        scale: 1,
        delay: i * this.settings.groupsAnimationInDelayStep,
        ease: this.settings.groupsAnimationInEase
      });
    });

    this.refs.revealAuthorTimeout = setTimeout(this.revealAuthor, ((quoteGroups.length * this.settings.groupsAnimationInDelayStep) + this.settings.groupsAnimationInDuration)*1000);
  }

  this.animateQuoteOut = (quote) => {
    TweenMax.killAll();

    if(this.refs.revealAuthorTimeout != undefined){
      clearInterval(this.refs.revealAuthorTimeout);
    }

    const quoteGroups = quote.querySelectorAll('g');
    const quoteGroupsNumber = quoteGroups.length;

    quoteGroups.forEach((group, i) => {
      TweenMax.to(group, this.settings.groupsAnimationOutDuration, {
        scale: 0,
        delay: i * this.settings.groupsAnimationOutDelayTotal / quoteGroupsNumber,
        ease: this.settings.groupsAnimationOutEase
      });
    });

    if (this.isAnimating == false) {
      this.refs.animationOutTimeout = setTimeout(() => {
        this.animateQuoteOutComplete(quote)
      }, (this.settings.groupsAnimationOutDuration + this.settings.groupsAnimationOutDelayTotal) * 1000 + this.settings.quotesAnimationInBetweenDelay);
    } else {
      clearTimeout(this.refs.animationOutTimeout);
      clearTimeout(this.refs.revealAuthorTimeout);
      this.isAnimating = false;
      this.animateQuoteOutComplete(quote)
    }
  }

  this.animateQuoteOutComplete = (quote) => {
    quote.classList.remove('active');
    quote.classList.remove('scaling');
    this.refs.$currentQuote.classList.add('active');
    this.resize();
    this.animateQuoteIn(this.refs.$currentQuote);
  }

  this.revealAuthor = () => {
    this.isAnimating = false;
    $citations[citationsCurrentID].classList.add('active');
  }

  this.nextQuote = () => {
    switchCitations(null, 'next');
  }

  this.switchQuote = (quoteID) => {
    this.refs.$previousQuote = this.refs.$currentQuote;

    this.currentID = quoteID;
    this.refs.$currentQuote = this.refs.$svgsContainer[this.currentID];

    this.animateQuoteOut(this.refs.$previousQuote);
  }
}


//citation
const $citations = document.querySelectorAll('.quotes-item');
const $pagination = document.querySelector('.pagination');
const $paginationCurrent = $pagination.querySelector('.current');
let   citationsCurrentID = Math.floor(Math.random() * $citations.length);

const svgQuotes = new SVGQuotes();
      svgQuotes.init(citationsCurrentID);

const initCitations = () => {
  $pagination.querySelector('.total').innerHTML = $citations.length;
  $paginationCurrent.innerHTML = citationsCurrentID+1;

  $pagination.querySelectorAll('a').forEach(link => {
    link.addEventListener('click', switchCitations);
    link.addEventListener('touchstart', switchCitations);
  });

  resizeCitations();
};

const resizeCitations = () => {
  svgQuotes.resize();
};

const switchCitations = (e, next) => {
  $citations[citationsCurrentID].classList.remove('active');

  if (e) {
    if (e.currentTarget.classList.contains('next')){
      citationsCurrentID = (citationsCurrentID < $citations.length-1) ? citationsCurrentID+1 : 0 ;
    } else {
      citationsCurrentID = (citationsCurrentID > 0) ? citationsCurrentID-1 : $citations.length-1 ;
    }
  } else if(next === 'next') {
    citationsCurrentID = (citationsCurrentID < $citations.length-1) ? citationsCurrentID+1 : 0 ;
  }

  $paginationCurrent.innerHTML = citationsCurrentID+1;

  if (e) {
    svgQuotes.killInterval();
  }

  svgQuotes.switchQuote(citationsCurrentID);
};









//images links
const $imageLinks = document.querySelectorAll('.image-link');
let   $imageLinkHovered = undefined;
let   imageLinksMouseOutTimeout = undefined;
const imageLinksMouseOutTimeoutDuration = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--d-image-links-overlays-out').split("s")[0]) * 1000;

const imageLinksMouseOverListener = (e) => {
  if(imageLinksMouseOutTimeout){
    clearTimeout(imageLinksMouseOutTimeout);
    imageLinksMouseOutTimeoutListener();
  }

  $imageLinkHovered = e.target;
  $imageLinkHovered.style.zIndex = '2000';
  $imageLinkHovered.style.position = 'relative';
  $imageLinkHovered.relatedOverlay.classList.add('active');

  if(isIE11){
    $imageLinkHovered.classList.add('hover');
  }

  if(!isIE11){
    if(e.type === 'click' || e.type === 'touchstart') {
      e.preventDefault();
      e.stopPropagation();
      $imageLinkHovered.classList.add('hover');
      $imageLinks.forEach(imageLink => {
        imageLink.classList.add('is-disabled');
      });
      document.body.addEventListener('click', imageLinksMouseOutListener);
      document.body.addEventListener('touchstart', imageLinksMouseOutListener);
    }
  }
};

const imageLinksMouseOutListener = (e) => {
  $imageLinkHovered.relatedOverlay.classList.remove('active');

  if(isIE11){
    $imageLinkHovered.style.zIndex = '0';
    $imageLinkHovered.style.position = 'auto';
    $imageLinkHovered.classList.remove('hover');
  }else {
    imageLinksMouseOutTimeout = setTimeout(imageLinksMouseOutTimeoutListener, imageLinksMouseOutTimeoutDuration);
  }

  if(!isIE11){
    if(e.type === 'click' || e.type === 'touchstart') {
      e.preventDefault();
      e.stopPropagation();
      $imageLinkHovered.classList.remove('hover');
      $imageLinks.forEach(imageLink => {
        imageLink.classList.remove('is-disabled');
      });
      document.body.removeEventListener('click', imageLinksMouseOutListener);
      document.body.removeEventListener('touchstart', imageLinksMouseOutListener);
    }
  }
};

const imageLinksMouseOutTimeoutListener = () => {
  $imageLinkHovered.style = '';
}

const initImageLinks = () => {
  $imageLinks.forEach(($imageLink, i) => {
    let $imageLinkOverlay = document.createElement('div');
        $imageLinkOverlay.classList.add('image-links-overlay');
        if(i%2){
          $imageLinkOverlay.classList.add('is-odd');
        }
        $imageLinkOverlay.style.backgroundImage = 'url("'+$imageLink.querySelector('img').getAttribute('src')+'")';

    $imageLink.parentNode.appendChild($imageLinkOverlay);
    $imageLink.relatedOverlay=$imageLinkOverlay;

    if (is_touch_device() === false) {
      $imageLink.addEventListener('mouseover', imageLinksMouseOverListener);
      $imageLink.addEventListener('mouseout', imageLinksMouseOutListener);
    } else {
      $imageLink.addEventListener('click', imageLinksMouseOverListener);
      $imageLink.addEventListener('touchstart', imageLinksMouseOverListener);
    }
  });
};








//bg change
const $bodyBgs = document.querySelectorAll('.body-bg__color');
const bodyBgNumber = $bodyBgs.length;
let   bodyBgId = Math.floor(Math.random() * bodyBgNumber);
let   bodyBgChangeInterval = undefined;
const bodyBgChangeIntervalDuration = 4000;

const initBodyBg = () => {
  $bodyBgs[bodyBgId].classList.add('no-transition');
  $bodyBgs[bodyBgId].classList.add('active');
  bodyBgChangeInterval = setInterval(changeBodyBg, bodyBgChangeIntervalDuration);
  if(window.devicePixelRatio > 1 && windowW > 1024){
    clearInterval(bodyBgChangeInterval);
  }
}

const changeBodyBg = () => {
  $bodyBgs[bodyBgId].classList.remove('no-transition');
  $bodyBgs[bodyBgId].classList.remove('active');
  bodyBgId = (bodyBgId < bodyBgNumber-1) ? bodyBgId+1 : 0 ;
  $bodyBgs[bodyBgId].classList.add('active');
};





//anchor links
const $navLinks = document.querySelectorAll('.nav-link');

const initNavLink = () => {
  $navLinks.forEach(link => {
    link.relatedSection = document.querySelector(link.getAttribute('href'));
    link.addEventListener('click', scrollToSection);
    link.addEventListener('touchstart', scrollToSection);
  });
}

const scrollToSection = (e) => {
  if(!isIE11){
    window.scrollTo({
      'behavior': 'smooth',
      'left': 0,
      'top': e.target.relatedSection.offsetTop
    });
    e.preventDefault();
  }
}







//general
const resizeListener = () => {
  windowW = (window.innerWidth || screen.width);
  windowH = (window.innerHeight || screen.height);

  resizeCitations();
};



//init
const init = () => {
  window.addEventListener('resize', resizeListener);
  resizeListener();

  initCitations();
  initImageLinks();
  initBodyBg();
  initNavLink();
}
init();

